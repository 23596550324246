<template>
  <div class="calendarsimple">
    <div class="calendar-contect">
      <div class="calendar-right-top row">
        <span class="calendar-right-title">Calendar</span>
        <el-button class="primary-button" @click="createLaese"> + New Lease </el-button>
      </div>
      <div class="calendar-note">
        <Note ref="note" :noteData="noteData" :total="total" @pagesChanged="pagesChanged"  @createNote="createNote" @updateEditNote="updateEditNote">
          <template slot="edit_btn">
              <el-table-column width="50">
              <template slot-scope="scope" > 
                <i style="color: #678993" class="el-icon-edit-outline" @click="openEditNoteDialog(scope.row)" ></i>
              </template>
            </el-table-column>
          </template>
          <template slot="table_btn">
            <el-table-column width="50">
              <template slot-scope="scope" > 
                <i style="color: #f56c6c" class="del-btn el-icon-delete" @click="openDelNoteDialog(scope.row.comment_uuid)" ></i>
              </template>
            </el-table-column>
          </template>
          <template slot="note_btn">
            <div class="note_btn">
              <a @click="openCreatedNote">+ Add Note</a>
            </div>
          </template>
        </Note>
        <Del-Note @del="delNote" :title="title" ref="delInfor"></Del-Note>
      </div>
      <div class="calendar-link">
        <p class="propety-title" style="text-align: left; ">Calendar Link</p>
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; gap: 10px; width: 100%;">
            <el-input v-model="calendarLink" placeholder="Optional: Paste your calendar link" style="width: 70%;" ></el-input>
            <el-button class="primary-button" @click="onSave">Save / Update</el-button>
        </div>
        <p class="propety-title" style="text-align: left;">
          If the homeowner maintains a property calendar at Vrbo or Airbnb, they can share a link with us to automatically update our calendar. To help a homeowner find this calendar link, share these instructions: <span><a style="text-decoration: underline;" href="https://help.vrbo.com/articles/Export-your-reservation-calendar" target="_blank">VRBO</a></span>, <span><a style="text-decoration: underline;" href="https://www.airbnb.com/help/article/99" target="_blank">Airbnb</a></span>. When you get the link from a homeowner, simply paste it here and our calendar will receive updates automatically and the homeowner will not receive email requests to update their calendar.
        </p>
      </div>
      <div class="year">Not Renting This Year: &nbsp;&nbsp;&nbsp;<el-date-picker v-model="data.not_renting_year" @change="selectNoYear" value-format="yyyy" format="yyyy" type="year" placeholder="Pick a year">  </el-date-picker>
      </div>
      <div class="row update-row">
        <div class="cal-btn">
          <el-popover placement="bottom" width="400" trigger="click" v-model="popoverShow">
            <Block-Avabaility @submit="blockAvailability" ref="blockInfor"> </Block-Avabaility>
            <el-button slot="reference" class="primary-button block-btn">Block Availability</el-button>
          </el-popover>
          <el-button class="primary-button update-btn" @click="save" v-loading.fullscreen.lock="fullscreenLoading" >Update / Validate</el-button>
        </div>
        <p class="last-update" v-if="lastUpdate != null">
          Last Updated on {{ lastUpdate.created_at | dateFormat }} by {{ lastUpdate.user_name }}
        </p>
      </div>
      <p class="turnover last-update" >Turnover Day: &nbsp;&nbsp;&nbsp;{{data.requirement&&data.requirement.turnover_day?data.requirement.turnover_day:null}}</p>
      <!-- date -->
      <div class="calendar-date">
        <div class="prev-month" @click="current--">
          <i class="el-icon-arrow-left"></i>&nbsp;
        </div>
        <div class="next-month" @click="current++">
          &nbsp;
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="wrapper">
          <div class="same_time">
            <Calendar class="calendar" :selectMonth="selectMonth" :rates="rates" :availabilities="availabilities" :month="current * 4" @select="select" @change="change" ></Calendar>
            <Calendar class="calendar" :selectMonth="selectMonth" :rates="rates" :availabilities="availabilities" :month="current * 4 + 1" @select="select" @change="change" ></Calendar>
          </div>
          <div class="same_time">
            <Calendar class="calendar" :selectMonth="selectMonth" :rates="rates" :availabilities="availabilities" :month="current * 4 + 2" @select="select" @change="change" ></Calendar>
            <Calendar class="calendar" :selectMonth="selectMonth" :rates="rates" :availabilities="availabilities" :month="current * 4 + 3" @select="select" @change="change" ></Calendar>
          </div>
        </div>
        <div class="row" style="justify-content: space-between; margin-top: 30px" >
          <div @click="current--">
            <i class="icon-btn el-icon-arrow-left icon-btn"></i>&nbsp;
          </div>
          <div @click="current++">
            &nbsp;
            <i class="el-icon-arrow-right icon-btn"></i>
          </div>
        </div>
        <!-- 选择是否可租 -->
        <el-dialog :visible.sync="choiceRentDialog" title="Unblock the dates?" >
          <div class="select_type">
            <el-radio-group v-model="rent">
              <el-radio :label="true">True</el-radio>
              <el-radio :label="false">False</el-radio>
            </el-radio-group>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="rentConfirm">Confirm</el-button>
          </span>
        </el-dialog>
        <!-- 选择不可租的类型 -->
        <div class="select-type">
          <el-dialog :visible.sync="choiceTypeDialog" >
            <p class="select-title">How do you want to block the dates?</p>
            <div class="select_type">
              <el-radio-group v-model="type">
                <el-radio label="owner">Owner Time</el-radio>
                <el-radio label="other">Other Office</el-radio>
                <el-radio label="leased">Create Lease</el-radio>
              </el-radio-group>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="choiceTypeDialog = false">Cancel</el-button>
              <el-button @click="typeConfirm">Confirm</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
      <div class="explain-box explain_row">
        <div class="explain explain_row">
          <div class="explain_setting explain_row">
            <i class="setting_color setting_color_lease"></i>
            <span class="setting_text">C&C Lease</span>
          </div>
          <div class="explain_setting explain_row">
            <i class="setting_color setting_color_owner"></i>
            <span class="setting_text">Owner Time</span>
          </div>
          <div class="explain_setting explain_row">
            <i class="setting_color setting_color_other"></i>
            <span class="setting_text">Other</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pb_btn">
      <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="fullscreenLoading" >Update / Validate</el-button >
    </div>
    <!--  -->
    <!-- update log -->
    <Update-Log ref="updateLog" @updateInfor="updateInfor"></Update-Log>
    <div class="pb_btn">
      <el-button class="primary-button next-btn" @click="next"
        ><p class="row btn-row">
          <span>Next</span><i class="el-icon-right"></i></p
      ></el-button>
    </div>
  </div>
</template>

<script>
import { apiGetListing, apiUpdateListing, apiCreateListingComment, apiGetListingComments, apiGetLeaseId, apiDelListingComments, apiUpdateListingComments, apiUpdatePropertyCalendar } from "../../API/api";
import Note from "../common/Note";
import Calendar from "./Calendar/Calendar";
import BlockAvabaility from "./Calendar/CalendarBlockAvabaility";
import dayjs from "dayjs";
import UpdateLog from "./Calendar/CalendarUpdateLog";
import DelNote from "../common/DelDialog";
export default {
  data() {
    return {
      popoverShow: false,
      fullscreenLoading: false,
      ratesObject: {}, // 存放用户新编辑的日历价格
      rates: {}, // 存放日历价格
      availabilities: [], // 存放日历可租性
      current: 0, // 0表示当前页数，下一页加一，上一页减一
      start: "", // 用户选择的开始日期
      end: "", // 用户选择的结束日期
      dateType: "owner",
      choiceRentDialog: false, // 选择是否可租对话框
      rent: true, // 默认勾选不可租
      choiceTypeDialog: false, // 选择不可租类型对话框
      type: "owner", // 默认勾选 owner
      noteData: [], // note
      total: 1,
      currentpage: 1,
      data: {not_renting_year:""},
      title: "Are you sure to delete this note?", // 删除note
      id: "",
      lastUpdate: null,
      noteEdit:"",
      selectMonth:null,
      noyear:"",
      calendarLink: ""
    };
  },
  components: { Calendar, Note, UpdateLog, DelNote, BlockAvabaility },
  computed: {
    offset() {
      return (this.currentpage - 1) * 5;
    },
    query() {
      return {
        offset: this.offset,
        limit: 5,
      };
    },
  },
  methods: {
    onSave(){
      console.log(this.calendarLink, this.$route.params.id)
      apiUpdatePropertyCalendar({'url':this.calendarLink, 'listing':Number(this.$route.params.id)})
            .then(
              (res) => {
                this.$message.success("Calendar updated successfully");
                this.calendarLink = "";
                this.$router.push({
                  name: "property-calendar",
                  params: { id: this.$route.params.id },
                });
              },
              (err) => {
                this.$message.error("Calendar update fail");
              }
            );
    },
    updateInfor(val) {// update log
      this.lastUpdate = val;
    },
    createLaese() {// 创建租约
      this.$router.push("/lease");
    },
    next() {
      this.$router.push({
        name: "property-photo",
        params: { id: this.$route.params.id },
      });
    },
    // note
    openDelNoteDialog(id) {// 删除评论
      this.id = id;
      this.$refs.delInfor.show = true;
    },
    delNote() {
      this.$refs.delInfor.loading = true;
      apiDelListingComments(this.$route.params.id, this.id).then((res) => {
        this.noteData.forEach((item, index) => {
          if (this.id === item.comment_uuid) {
            this.noteData.splice(index, 1);
            this.$refs.delInfor.show = false;
          }
        });
      }).finally(()=>{ this.$refs.delInfor.loaidng = false; });
    },
    openCreatedNote() {
      this.$refs.note.show = true;
    },
    createNote(note) {
      this.$refs.note.loading = true;
      apiCreateListingComment(this.$route.params.id, {content: note, }).then((res) => {
        this.$refs.note.note = "";
        this.$refs.note.show = false;
        this.getNotes();
      }).finally(()=>{this.$refs.note.loading = false;});
    },
    openEditNoteDialog(item){
      this.$refs.note.showEdit = true;
      this.$refs.note.noteEdit = item.content
      this.noteEdit = item
    },
    updateEditNote(note){
      this.$refs.note.loadingNoteEdit = true;
      apiUpdateListingComments(this.$route.params.id,this.noteEdit.comment_uuid,{content:note}).then(res=>{
        this.$refs.note.showEdit = false;
        this.$refs.note.datas.forEach((item,index) => {
          if(this.noteEdit.comment_uuid===item.comment_uuid){
            let a = this.$refs.note.datas[index];
            a.content = note;
            this.$set(this.data, index, a);
          }
        })
      }).finally(()=>{this.$refs.note.loadingNoteEdit = false;});
    },
    getNotes() {
      apiGetListingComments(this.$route.params.id, this.query).then((res) => {
        this.noteData = res.results;
        this.total = res.count;
      });
    },
    pagesChanged(number) {
      this.currentpage = number;
      this.getNotes();
    },
    // 价格输入框改变
    change(rate) {
      this.ratesObject[rate.from_date] = rate;
      this.$set(this.rates, rate.from_date, rate);
    },
    selectNoYear(){//选择不可租的年份
    let obj= {not_renting_year:this.data.not_renting_year?dayjs(this.data.not_renting_year).format("YYYY"):null}
       apiUpdateListing(this.$route.params.id, obj).then((res) => {
         this.$message.success("success");
        })
        .catch((err) => {
          this.$message.error(err.response.data&&err.response.data.detail?err.response.data.detail:"Updated fail");
        })
    },
    // 保存
    save() {
      this.updateListing({ rates: Object.values(this.ratesObject) });
    },
    getLeaseId(start, end) {
      let obj = {
        listing: this.$route.params.id,
        arrival_date: start,
        departure_date: end,
      };
      apiGetLeaseId(obj).then((res) => {
        // 查看租约
        if (res.leases.length >= 1) {
          this.$store.commit("setLeaseAgeent", false);
          this.$store.commit("setLeaseSecurityDeposit", false);
          this.$router.push({
            name: "edit-lease",
            params: { id: res.leases[0] },
          });
        }
      });
    },
    select(start, end, availabilities) {// 选择日历
      this.start = start;
      this.end = end;
      let selectFlag = false;
      let selectShow = false;
      let flag = false;
      let arr = [];
      availabilities.forEach((item) => {
        if (
          (dayjs(item.from_date) >= dayjs(this.start) ||
            dayjs(item.to_date) > dayjs(this.start)) &&
          dayjs(item.from_date) < dayjs(this.end)
        ) {
          arr.push(item);
        }
      });
      if (arr.length <= 1) {
        flag = availabilities.every((availability) => {
          if (
            dayjs(availability.from_date) > dayjs(this.end) ||
            dayjs(availability.to_date) <= dayjs(this.start)
          ) {
            return true;
          } else {
            if (availability.from_date === this.end) {
              return true;
            } else {
              if (availability.type === "leased") {
                this.getLeaseId(this.start, this.end);
                selectFlag = true;
              } else {
                this.dateType = availability.type;
                selectFlag = false;
              }
            }
          }
        });
      } else {
        selectShow = true;
      }
      if (!selectShow) {
        if (flag) {
          // 选中的日期段都可租
          this.choiceTypeDialog = true;
        } else {
          // 选中的日期段存在不可租的日期
          if (selectFlag) {
          } else {
            this.choiceRentDialog = true;
          }
        }
      }
    },
    // 是否可租对话框确认
    rentConfirm() {
      if (this.rent) {
        this.updateListing({
          availabilities: [
            {
              from_date: this.start,
              to_date: this.end,
              availability: true,
              type: this.dateType, // 可租时，类型随便传一个值
            },
          ],
        });
      } else {
        this.choiceTypeDialog = true;
      }
      this.choiceRentDialog = false;
    },
    // 不可租类型选择对话框确认
    typeConfirm() {
      if (this.type === "leased") {
        // 跳转到创建租约页面
        this.$store.commit("setLeaseParam", {
          listing: this.$route.params.id,
          listingAddress: this.data.address,
          arrivalDate: this.start,
          departureDate: this.end,
        });
        this.$router.push({ name: "create-lease" });
      } else {
        this.updateListing({
          availabilities: [
            {
              from_date: this.start,
              to_date: this.end,
              availability: false,
              type: this.type,
            },
          ],
        });
      }
      this.choiceTypeDialog = false;
    },
    close(){
      this.$refs.blockInfor.data.from_date = this.$refs.blockInfor.data.to_date = this.$refs.blockInfor.data.type=""
      this.popoverShow = false;
    },
    blockAvailability(data) {
      let obj = { availabilities: [] };
      obj.availabilities.push(data);
      this.selectMonth = dayjs(data.from_date).format('YYYY-MM')
      this.updateListing(obj)
    },
    // 更新房屋详情
    updateListing(data) {
      this.fullscreenLoading = true;
      if (data.rates) {
        data.rates.forEach((item) => {
          if (!item.amount) {
            item.amount = 0;
          }
        });
      }
      apiUpdateListing(this.$route.params.id, data)
        .then((res) => {
          this.close();
          this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
          this.$store.commit("setPropertyCalendar", true);
          this.availabilities = res.availabilities;
          this.rates = res.rates.reduce((previous, current) => {
            previous[current.from_date] = current;
            return previous;
          }, {});
          this.$refs.blockInfor.data.date = [];
          this.$refs.updateLog.currentPage = 1;
          this.$refs.updateLog.getUpdateLog(); // 方法2:直接调用
          this.$message.success("Updated successfully");
        })
        .catch((err) => {
          this.$message.error("Updated fail");
        }).finally(() => {
          this.fullscreenLoading = false;
        });
    },
    //获取房屋详情
    getListing() {
      apiGetListing(this.$route.params.id) .then((res) => {
          this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
          this.$store.commit("setPropertyInfor", true);
          this.$store.commit("setPropertyReuirement", true);
          if (res.availabilities.length != 0 || res.rates.length != 0) {
            this.$store.commit("setPropertyCalendar", true);
          }
          if (res.images.length != 0) {
            this.$store.commit("setPropertyPhoto", true);
          }
          this.data = res;
          this.data.not_renting_year = res.not_renting_year?res.not_renting_year+"/01"+"/01":"";
          this.availabilities = res.availabilities;
          this.rates = res.rates.reduce((previous, current) => {
            previous[current.from_date] = current;
            return previous;
          }, {});
        })
        .catch((err) => {});
    },
    // 上一步
    preStep() {
      this.$router.push({
        name: "edit-property",
        params: { id: this.$route.params.id },
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   let listing = JSON.parse(JSON.stringify(this.$store.state.listing));
      //   this.listingAddress = listing.address;
      //   this.availabilities = listing.availabilities;
      //   this.data = listing;
      //   this.rates = listing.rates.reduce((previous, current) => {
      //     previous[current.from_date] = current;
      //     return previous;
      //   }, {});
      // } else {
      this.getListing();
      // }
      this.getNotes();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./PropertyCalendar.scss";
.icon-btn {
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}
.year{
  text-align: left;
  margin-top: 20px;
}
.turnover{
  text-align: left;
}
.calendar-link{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 640px;
}
</style>
