<template>
  <div class="contect">
    <el-form :model="data" :rules="rules" ref="data">
      <div class="row">
        <el-form-item prop="date" label="CHOOSE DATE" :rules="rules.require" >
          <el-date-picker style="width:100%" v-model="data.date" type="daterange" :default-value="date.date" range-separator="-" format="MM/dd/yyyy" value-format="yyyy-MM-dd" start-placeholder="START DATE" end-placeholder="END DATE">
          </el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="TYPE" prop="type" :rules="rules.require">
        <el-select v-model="data.type" placeholder="">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button class="primary-button" @click="submit('data')">Save</el-button>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs"
export default {
  data() {
    return {
      data: { availability: false, type: "",date:[], to_date: "", from_date: "" },
      options: [
        {
          label: "Owner Time",
          value: "owner",
        },
        {
          label: "Other Office",
          value: "other",
        },
      ],
      rules: {
        require: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
        ],
      },
      date:{date:""}
    };
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.data);
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    "data.date"(newV) {
      if (newV!=null&&newV.length!=0) {
        this.data.from_date = newV[0];
        this.data.to_date = newV[1];
      }
    },
  },
  created(){
    let myDate = new Date();
    let tYear = myDate.getFullYear();
    let tMonth = myDate.getMonth() + 1;
    let date=""
    if (tMonth > 7) {
      date = tYear + 1 + "/5"+"/1";
    } else {
      date = tYear + "/5"+"/1";
    }
    this.date.date = date;
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.contect {
  /deep/ .el-form-item {
    .el-form-item__label {
      font-family: "Roboto-Regular";
      font-size: 14px;
      line-height: 20px;
      &::before {
        display: none;
      }
    }
    .el-select {
      width: 100%;
    }
  }
  .row {
    justify-content: space-between;
    /deep/ .el-form-item {
      // width: calc(calc(100% - 20px) / 2);
      width: 100%;
      .el-input {
        width: 100%;
      }
    }
  }
  .el-button {
    width: 200px;
  }
}
</style>