<template>
  <div class="update-log">
    <p class="update-title">UPDATE LOG</p>
    <div class="table">
      <el-table :data="data" style="width: 100%">
        <el-table-column prop="date" label="DATE" width="120">
          <template slot-scope="{ row }">
            {{ row.created_at | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="user_name" label="UPDATED BY" width="200">
        </el-table-column>
        <el-table-column prop="type" label="TYPE" width="130"> </el-table-column>
        <el-table-column label="OLD" min-width='240'>
          <template slot-scope="{ row }">
            <div v-if="row.type==='rates'"><p v-for="(item,index) in row.content" :key="index">{{item.from_date | dateFormat}} - {{item.to_date | dateFormat}} = {{item.old_value | currencyPrice("$ ")}}</p></div>
            <div v-else-if="row.type==='availability'">
              <p v-for="(item,index) in row.content" :key="index">{{item.from_date | dateFormat}} - {{item.to_date | dateFormat}} = {{item.old_value?"Available": item.type | capitalize}}</p>
             </div>
          </template>
        </el-table-column>
        <el-table-column label="NEW" min-width="240">
           <template slot-scope="{ row }">
             <div v-if="row.type==='rates'">
              <p v-for="(item,index) in row.content" :key="index">{{item.from_date | dateFormat}} - {{item.to_date | dateFormat}} = {{item.new_value | currencyPrice("$ ")}}</p>
             </div>
             <div v-else-if="row.type==='availability'">
              <p v-for="(item,index) in row.content" :key="index">{{item.from_date | dateFormat}} - {{item.to_date | dateFormat}} = {{item.new_value?"Available": item.type | capitalize}} </p>
             </div>
             <div v-else-if="row.type==='validate' && row.content[0]?.msg === 'validated via email link'">
                <p> Validated by homeowner via email link</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="5" @number="pagesChanged" :currentPage="currentPage"></Page>
  </div>
</template>
<script>
import Page from "../../common/Paging"
import { apiGetCalendarUpdate } from "../../../API/api";
export default {
  components:{ Page },
  data() {
    return {
      data: [],
      currentPage:1,
      limit:5,
      total:1
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      return {
        limit: this.limit,
        offset: this.offset
      };
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    getUpdateLog() {
      apiGetCalendarUpdate(this.$route.params.id,this.query).then((res) => {
        this.data=res.results;
        this.total=res.count;
        if(this.offset===0){
          this.$emit("updateInfor",res.results[0])
        }
      });
    },
    pagesChanged(number){
      this.currentPage = number;
      this.getUpdateLog();
    }
  },
  created() {
    this.getUpdateLog()
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
.update-log {
  padding: 20px;
  .update-title {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0px;
  }
  /deep/ .table {
    td {
      padding: 10px 0;
    }
  }
}
</style>