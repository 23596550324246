<template>
  <div class="same_month">
    <full-calendar class="calendar"  ref="calendar" :config="config" :events="events" ></full-calendar>
    <div class="same_price">
      <p class="price-title">
        <b>Price</b>
      </p>
      <div v-for="(rate, index) in ratesList" :key="index">
        <p class="input-price">
          <el-input v-model="rate.amount" @change="change(rate)">
            <strong slot="prefix" class="strong">$</strong>
          </el-input>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// https://fullcalendar.io/docs/other-calendar-dragging
import "../../../css/fullcalendar.css";
import { FullCalendar } from "vue-full-calendar";
import dayjs from "dayjs";
export default {
  name: "Calendar",
  data() {
    return {
      ratesList: [], // 存放价格列表
      config: {
        defaultDate: this.defaultDate, // 默认日期
        header: { left: "", center: "title", right: "" },
        defaultView: "month", // 显示默认月份
        firstDay: 0, // 以周日做为一周的开始
        eventColor: "#fff", // 事件颜色
        select: this.select,
        currentTimezone: true,
        fixedWeekCount: false,//设置周数
        // showNonCurrentDates: false,//去掉不属于当月的日期
        // dayClick: this.days
      },
      date: "",
    };
  },
  props: ["rates", "availabilities", "month", "selectMonth"],
  computed: {
    defaultDate() {
      return dayjs(this.date).add(this.month, "month").format("YYYY-MM");
    },
    first_date() {
      // 该月日历上的第一天
      let week = dayjs(this.defaultDate).startOf("day").$W; // 星期几，星期日为0
      return dayjs(this.defaultDate).startOf("day").subtract(week, "day").format("YYYY-MM-DD");
    },
    last_date() {
      // 该月日历上的最后一天
      return dayjs(this.first_date).add(6 * 7 - 1, "day").format("YYYY-MM-DD");
    },
    partAvailabilities() {
      if (this.availabilities) {
        let availabilities = [];
        this.availabilities.forEach((item) => {
          if (
            dayjs(item.from_date) > dayjs(this.last_date) ||
            dayjs(item.to_date) < dayjs(this.first_date)
          ) {
            return;
          }
          availabilities.push(item);
        });
        return availabilities;
      }
    },
    events() {
      if (this.availabilities) {
        let events = [];
        this.partAvailabilities.forEach((item) => {
          let color = "";
          if (item.type === "other") {
            // red
            color = "red";
          } else if (item.type === "owner") {
            // yellow
            color = "yellow";
          } else {
            // blue
            color = "blue";
          }
          events.push({
            rendering: "background",
            start: dayjs(item.from_date).format("YYYY-MM-DD"),
            end: dayjs(item.from_date).format("YYYY-MM-DD"),
            className: `${color}_start`,
          });
          if (dayjs(item.to_date).diff(dayjs(item.from_date), "day") > 1) {
            // 大于1天
            events.push({
              rendering: "background",
              start: dayjs(item.from_date).add(1, "day").format("YYYY-MM-DD"),
              end: dayjs(item.to_date).format("YYYY-MM-DD"),
              className: `${color}`,
            });
          }
          events.push({
            rendering: "background",
            start: dayjs(item.to_date).format("YYYY-MM-DD"),
            end: dayjs(item.to_date).format("YYYY-MM-DD"),
            className: `${color}_end`,
          });
        });
        return events;
      }
    },
  },
  watch: {
    defaultDate(newVal) {
      this.setRatesList();
      this.$refs.calendar.fireMethod("gotoDate", newVal);
    },
    rates() {
      this.setRatesList();
    },
    selectMonth(newVal) {
      this.date = dayjs(newVal).startOf('month').format('YYYY-MM-DD')
    },
  },
  components: { FullCalendar },
  methods: {
    days(){ 
      // console.log("object");
      },
    // 设置当月每周的价格
    setRatesList() {
      this.ratesList.length = 0; // 清空之前的数据
      let firstSunday = dayjs(this.defaultDate).set("day", 0); // 该月份第一个星期的星期天
      for (let i = 0; i < 6; i++) {
        let key = firstSunday.add(i * 7, "day").format("YYYY-MM-DD");
        if (this.rates && this.rates[key]) {
          this.ratesList.push(this.rates[key]);
        } else {
          this.ratesList.push({
            from_date: key,
            to_date: dayjs(key).add(6, "day").format("YYYY-MM-DD"),
            amount: "",
          });
        }
        // 删除不属于这个月份的价钱
        this.ratesList.forEach((item,index) => {
          if(dayjs(item.from_date).format("MM")!=dayjs(this.defaultDate).format("MM")&&dayjs(item.to_date).format("MM")!=dayjs(this.defaultDate).format("MM")){
            this.ratesList.splice(index,1)
          }
        });
      }
    },
    // 价格输入框改变
    change(rate) {
      this.$emit("change", rate);
    },
    // 选择日期
    select(start, end) {
      // console.log(start,end,"date");
      let start_time = start.year() + "-" + (start.month() + 1) + "-" + start.date();
      let end_time = end.year() + "-" + (end.month() + 1) + "-" + end.date();
      if ( dayjs(start_time).format("YYYY-MM-DD") === dayjs(end_time).add(-1, "day").format("YYYY-MM-DD") ) {
        this.$emit( "select", dayjs(start_time).format("YYYY-MM-DD"), dayjs(end_time).format("YYYY-MM-DD"), this.partAvailabilities );
      } else {
        this.$emit( "select", dayjs(start_time).format("YYYY-MM-DD"), dayjs(end_time).add(-1, "day").format("YYYY-MM-DD"), this.partAvailabilities );
      }
    },
  },
  mounted() {
    this.setRatesList();
    this.$refs.calendar.fireMethod("gotoDate", this.defaultDate);
  },
  created() {
    let myDate = new Date();
    let tYear = myDate.getFullYear();
    let tMonth = myDate.getMonth() + 1;
    if (tMonth > 7) {
      this.date = tYear + 1 + "/5"+"/01";
    } else {
      this.date = tYear + "/5"+"/01";
    }
  },
};
</script>

<style lang="scss" scoped>
// 日历
.same_month {
  display: flex;
  /deep/ .fc-basic-view .fc-body .fc-row {
    height: 0.571429rem !important;
  }
  /deep/ .fc-highlight {
    z-index: 999;
    opacity: 0.9;
  }
  /deep/ .fc-content-skeleton tbody {
    display: none;
  }
  /deep/ .fc-unthemed td.fc-today {
    background: none;
  }
  /deep/ .fc-scroller {
    height: 4.892857rem !important;
    overflow: hidden !important;
  }
  /deep/ .fc-row .fc-bgevent-skeleton {
    z-index: 9999;
  }
  /deep/ .fc-day-top {
    height: 35px;
    text-align: center;
  }
  /deep/ .fc {
    width: calc(100% - 100px);
    th,
    td {
      vertical-align: inherit;
      border: none;
    }
    td.fc-today {
      border: none;
    }
    th span {
      font-size: 14px;
      color: #2c3f58;
      font-weight: normal;
    }
    a {
      text-decoration: none;
    }
  }
  /deep/ .fc-row {
    .fc-content-skeleton thead td {
      background-size: cover;
      background-color: #fff;
      border: none;
      margin-bottom: 6px;
      z-index: -666;
      opacity: 0.78;
      height: 35px;
    }
  }
  /deep/ .fc-row.fc-widget-header {
    margin-bottom: 20px;
  }
}

// 颜色
.calendar /deep/ .red {
  background-color: #de1b4b !important;
  height: 32px;
}
.calendar /deep/ .yellow {
  background-color: #678993 !important;
height: 32px;
}
.calendar /deep/ .blue {
  background-color: #d4af37 !important;height: 32px;
}
.calendar /deep/ .red_start {
  background: linear-gradient(
    to right bottom,
    rgba(255, 0, 0, 0) 50%,
    #de1b4b 0
  ) !important;height: 32px;
}
.calendar /deep/ .red_end {
  background: linear-gradient( to left top, rgba(255, 0, 0, 0) 50%, #de1b4b 0 ) !important;
   height: 32px;
}
.calendar /deep/ .yellow_start {
  background: linear-gradient( to right bottom,
    rgba(255, 0, 0, 0) 50%,
    #678993 0
  ) !important;height: 32px;
}
.calendar /deep/ .yellow_end {
  background: linear-gradient(
    to left top,
    rgba(255, 0, 0, 0) 50%,
    #678993 0
  ) !important;height: 32px;
}
.calendar /deep/ .blue_start {
  background: linear-gradient(
    to right bottom,
    rgba(255, 0, 0, 0) 50%,
    #d4af37 0
  ) !important;height: 32px;
}
.calendar /deep/ .blue_end {
  background: linear-gradient(
    to left top,
    rgba(255, 0, 0, 0) 50%,
    #d4af37 0
  ) !important;height: 32px;
}
// 价格
.same_price {
  width: 100px;
  margin-top: 48px;
  margin-left: 10px;
  p {
    margin: 0;
    text-align: left;
    b {
      font-weight: normal;
      font-family: "Roboto-Bold", sans-serif;
    }
  }
  .price-title {
    margin-bottom: 20px;
  }
  .input-price {
    margin-bottom: 9px;
  }
  /deep/ .el-input {
    // margin-bottom: 9px;
    height: 32px;
    border-radius: 4px;
  }
  /deep/ .el-input__inner {
    height: 100%;
    border-radius: 4px;
  }
  /deep/ .el-input--prefix .el-input__inner {
    padding-left: 15px;
  }
  /deep/ .el-input__prefix{display: flex;  align-items: center;}
}
@media screen and (max-width: 1200px) {
  .same_month {
    width: calc(calc(100% - 20px) / 2);
  }
}
@media screen and (max-width: 772px) {
  .same_month {
    width: 98%;
    max-width: 720px;
  }
}
@media (max-width: 500px) {
   .same_price{ .input-price  {
    margin-bottom: 4px;
  }
  /deep/ .el-input {
    height: 28px;
  }}
}
// fc-day-top fc-mon fc-other-month fc-future
</style>
